@import '../../styles/customMediaQueries.css';

.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }
  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.error {
  color: var(--colorFail);
}

.aside {
  box-shadow: none;
}

.asideContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--colorGrey100);

  @media (--viewportLarge) {
    flex-direction: column;
    border-bottom: none;
  }
}

.avatar {
  margin: 30px 26px 29px 0;
  flex-shrink: 0;

  @media (--viewportLarge) {
    margin: 0 96px 48px 0;
  }
}

.mobileHeading {
  flex-shrink: 0;
  margin: 60px 0 0 0;

  @media (--viewportMedium) {
    margin: 49px 0 0 0;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkMobile {
  margin-top: 17px;

  /* Pull the link to the end of the container */
  margin-left: auto;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkDesktop {
  display: none;

  @media (--viewportLarge) {
    display: inline;
    padding: 6px 0 2px 0;
  }
}

.desktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0 0 24px 0;
  }
}

.bio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 5px 0 24px 0;

  @media (--viewportMedium) {
    margin: 4px 0 51px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 56px 0;
    max-width: 600px;
  }
}

.listingsContainer {
  composes: clearfix from global;
  border-top: 1px solid var(--colorGrey100);
  padding-bottom: 23px;

  @media (--viewportMedium) {
    padding-bottom: 22px;
  }
}

.listingsTitle {
  color: var(--colorGrey700);
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 48px;
    margin-bottom: 8px;
  }
  @media (--viewportLarge) {
    margin-top: 56px;
    margin-bottom: 8px;
  }
}

.withBioMissingAbove {
  /* Avoid extra borders in mobile when bio is missing */
  border-top: none;

  @media (--viewportLarge) {
    border-top: 1px solid var(--colorGrey100);
  }
}

.listings {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.listing {
  width: 100%;

  /* Single column in mobile */
  margin-top: 34px;

  @media (--viewportSmall) {
    /* width: calc(20% - 12px); */

    /* 4 columns in desktop */
    width: calc(25% - 12px);

    margin-bottom: 36px;

    margin-right: 12px;
    /* Vertical space */
    margin-top: 0;
  }

  @media only screen and (max-width: 600px) {
    width: calc(50% - 12px);
    margin-right: 10px;
  }
}

.mobileReviewsTitle {
  margin: 24px 0 26px 0;
}

.mobileReviews {
  border-top: 1px solid var(--colorGrey100);
  margin-bottom: 36px;
}

.desktopReviews {
  border-top: 1px solid var(--colorGrey100);

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.desktopReviewsWrapper {
  max-width: 600px;
  margin-top: 28px;
}

.desktopReviewsTitle {
  composes: textLarge from global;
  margin-bottom: 0;
}

.desktopReviewsTabNav {
  margin-bottom: 32px;
}

.sectionText {
  padding: 0 24px;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

/* 
 * Custom field component classes 
 */

.sectionHeading {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionMultiEnum,
.sectionDetails {
  padding: 0 24px;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: transparent;
}

.gotoListingPage {
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
  /* text-align: center; */
  width: 100%;
}
.gotoListingPage a {
  padding: 12px 24px;
  background-color: black;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.08);
  text-decoration: none;
  font-size: 14px;
  font-weight: 800;
  transition: all 0.2s ease;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.06);
  position: relative;
  top: 0;
}

.gotoListingPage a::after {
  content: '→';
  margin-left: 4px;
  transition: transform 0.2s ease;
}

.gotoListingPage a:hover {
  background-color: var(--marketplaceColor);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06), 0 2px 4px rgba(0, 0, 0, 0.08);
  top: -1px;
  transform: translateY(-1px);
}

.gotoListingPage a:hover::after {
  transform: translateX(2px);
}

.gotoListingPage a:active {
  background-color: #e2e6ed;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
  top: 1px;
  transform: translateY(1px);
}
